import React, { useEffect } from 'react';
import './Video.css';

import { useDispatch, useSelector } from 'react-redux';
import { pathVideo } from 'config';
// import { getPerson } from 'store/ducks/person/actions';

import { MoreArrow } from 'components';
import { videosSelector } from 'store/ducks/homepage/selectors/videosSelector';
// import { personsSelector } from 'store/ducks/homepage/selectors/personsSelector';

export function Video() {
    const dispatch = useDispatch();

    let videos = useSelector(videosSelector);

    const { status } = useSelector(
        (state: {
            homepage: { status: string; statusMaterialsLoadMore: string };
        }) => state.homepage
    );

    // const personList = useSelector(personsSelector);
    // const status = useSelector(
    //   (state: { person: { status: string } }) => state.person.status
    // );

    // useEffect(() => {
    //   if (status == 'IDLE') {
    //     dispatch(getPerson());
    //   }
    // }, []);

    return (
        <section className="video">
            <h2 className="page-title video__title">Видео</h2>
            <MoreArrow type="video" link={pathVideo}>
                Все видео
            </MoreArrow>
            {videos &&
                videos.length > 0 &&
                [videos[0], videos[1], videos[2]].map(item => {
                    if(item){
                        const { id, link, img, tag, title, type, isShowTag, date } = item;
                        return (
                            <article className="card card--m" key={id}>
                                <a className="materials__link" href={link}>
                                    <img
                                        src={img}
                                        alt={title}
                                        className="materials__img"
                                    />
                                    <div className='materials__common'>
                                        <h3
                                            className="materials__title"
                                            dangerouslySetInnerHTML={{ __html: title }}
                                        ></h3>
                                        <div className="materials__date">{date}</div>
                                    </div>
                                </a>
                            </article>
                        );
                    }
                })}
        </section>
    );
}
