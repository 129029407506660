import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { connect } from 'react-redux';
// import { State, Sneakers as SneakersType } from 'types';
// import { fetchHomepage } from 'store/ducks/homepage/actions';
// import { fetchNews } from 'store/ducks/news/actions';
// import { getHomepage, isLoading } from 'store/ducks/homepage/selectors';
// import { HomeStub } from './Home.stub';
// import { getNews } from '../../components/News/actions';
import {
    Main,
    Newsfeed,
    Articles,
    Subscription,
    Others,
    Person,
    PageMeta,
    Page,
} from 'components';

import { getHomepage, getHomepageIdle } from 'store/ducks/homepage/actions';
import { StateStatus } from 'types/models';
import { Video } from 'components/Video/Video';
import { Spb } from 'components/Spb/Spb';

type Props = {};

const Home = (props: Props) => {
    const dispatch = useDispatch();

    const { status } = useSelector(
        (state: { homepage: { status: StateStatus } }) => state.homepage
    );

    useEffect(() => {
        if (status == 'IDLE') {
            dispatch(getHomepage());
        }
    }, []);

    return (
        <Page>
            <PageMeta
                title="Онлайн-журнал Телеспутник – новости о телекоме, ИКТ, цифровизации, кибербезопасности и медиапотреблении"
                description="Онлайн-журнал Телеспутник является одним из самых авторитетных и быстроразвивающихся интернет-порталов в области телевизионной индустрии, рынка OTT, медиапотребления и производства контента."
                url="https://telesputnik.ru"
            />
            <Main />
            <Newsfeed />
            <Articles />
            <Subscription page="" mod="" />
            <Others />
            <Video />
            <Spb />
            <Person />

            <div className="news">
                {/* {news.map(item => (
                    <div key={item.id}>{item.title}</div>
                ))} */}
            </div>
        </Page>
    );
};

export default Home;
